<template>
  <div>
    <component :is="dynamicComponent" :boardRef="boardRef" />
    <router-view></router-view>
  </div>
</template>

<script>
import { GroupDealTableWidget, HomeDealTableWidget, IndividualDealTableWidget } from '../widgets'

export default {
  name: 'DealTableView',
  components: {
    GroupDealTableWidget,
    HomeDealTableWidget,
    IndividualDealTableWidget
  },
  props: {
    boardRef: { type: String, required: true }
  },
  computed: {
    dynamicComponent() {
      // TODO: use a more reliable marker than the board ID
      switch (this.boardRef) {
        case 'life-group':
          return 'GroupDealTableWidget'
          break
        case 'home':
          return 'HomeDealTableWidget'
          break
        default:
          return 'IndividualDealTableWidget'
          break
      }
    }
  }
}
</script>
